<template>
  <div>
    <div class="box" v-if="languageSelect==='zh'">
      <div class="ViewTitle">资讯管理</div>
      <div class="contain">
        <div v-if="isLoading===true" style="width: 90%;min-height:45vh" >
          <template>
            <div class="notLogin">
              <img loading="lazy" src="@/assets/none.png" alt="" />
              更多数据，等你发现
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="box" v-if="languageSelect==='en'">
      <div class="ViewTitle">Information management</div>
      <div class="contain">
        <div v-if="isLoading===true" style="width: 90%;min-height:45vh" >
          <template>
            <div class="notLogin">
              <img loading="lazy" src="@/assets/none.png" alt="" />
              More data waiting to be found
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import { shareUrl } from "@/utils/vxshare.js";
export default {
  name: "Information",
  components: {},
  data() {
    return {
      isLoading:true,
      languageSelect:'',

    };
  },
  computed: {},
  created() {
    if(localStorage.getItem('language')){
      this.languageSelect=localStorage.getItem('language')
    }else{
      this.languageSelect='zh'
    }
    let url = window.location.host
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url:url,
        title:'油气人才',
        text:'全球油气行业专业人才求职招募平台，AI辅助简历优化，在线雇主及时沟通，更多油气行业岗位，快来油气人才吧！',
      }
      shareUrl(shareData);
    }, 1000);
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.box {
  background: #fff;

  .ViewTitle {
    height: 67px;
    line-height: 67px;
    border-bottom: 1px solid #ebebeb;
    font-size: 20px;
    color: #333333;
    font-weight: 400;
    padding: 0 30px;
  }

  .contain {
    margin: 0 30px;
    padding-bottom: 64px;
    padding-top: 30px;

    .submit {
      width: 320px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      font-size: 18px;
      color: #ffffff;
      border-radius: 28px;
      background: var(--main-color);
      margin-left: 100px;
      cursor: pointer;
      margin-top: 56px;
    }
  }
}

</style>
